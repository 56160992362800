<template>
  <div class="mt-5 is-flex">
    <b-loading
      :active="isLoading"
    />
    <div v-if="!isLoading">
      <div v-if="FactoryData.branch" class="has-background-white px-4 py-4 mr-5 main-inner-set">
        <div class="mb-3">
          <p class="is-uppercase heading-text-size has-text-grey has-text-weight-medium mb-3">Редактировать Завод</p>
          <small>Введите данные</small>
        </div>

        <b-field label="Компания">
          <b-select
            placeholder="Выберите компанию"
            v-model="FactoryData.branch.company.id"
            required
          >
            <option
              v-for="option of Company_list"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>

        <b-field
          label="Филиал"
          class="mt-3"
        >
          <b-select
            placeholder="Выберите филиал"
            v-model="FactoryData.branch.id"
            required
          >
            <option
              v-for="option of CompanyBranches"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>

        <label>
          <b>Наименование завода</b>
        </label>
        <input
          class="input"
          type="text"
          placeholder="Наименование завода"
          v-model="FactoryData.name"
          required
        >
        <div class="mt-3">
          <button
            class="button is-info"
            @click="SaveChangesFac"
          >Сохранить
          </button>
          <button class="button is-warning mx-3">Деактивировать</button>
          <button
            class="button is-danger"
            @click="DeleteFactory"
          >Удалить
          </button>
        </div>
      </div>
    </div>

    <div class="has-background-white px-4 py-4 main-inner-set">
      <h4 class="heading is-size-6 has-text-weight-medium">СПИСОК CMS</h4>
      <ul
        v-for="item of CmsData"
        :key="item.id"
        class="list ml-5"
      >
        <li>
          <a @click="EditCms(item.id)">{{ item.name }}</a>
        </li>
      </ul>
      <router-link
        class="has-text-weight-medium"
        to="/cms-registration"
      >Зарегистрировать CMS
      </router-link>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      isLoading: true,
      FactoryData: [],
      Company_list: [],
      factory_id: null,
      selected_company: null,
      CompanyBranches: [],
      selected_branch: null,
      CmsData: [],
      factory_name: null,
    };
  },
  watch: {
    "FactoryData.branch.company.id"(newValue) {
      this.getCompanyBranches(newValue);
    },
  },
  created() {
    this.getCompanies();
    this.getFactoryData();
    this.GetCmsList();
  },
  methods: {
    async getFactoryData(e) {
      this.isLoading = true;
      if (e) {
        this.factory_id = e;
      } else {
        this.factory_id = this.$router.history.current.params.id.replace(/[^0-9]/g, "");
      }

      await this.$axios.get(`admin/factories/${this.factory_id}`).then(response => {
        this.FactoryData = response.data.data;
        this.getCompanyBranches(this.FactoryData.branch.company.id);
      });
      this.isLoading = false
    },


    async getCompanies() {
      await this.$axios.get(`admin/companies`).then(response => {
        this.Company_list = response.data.data;
      });
    },
    async getCompanyBranches(e) {
      await this.$axios.get(`admin/companies/${e}/branches`).then(response => {
        this.CompanyBranches = response.data.data;
      });
    },
    async DeleteFactory() {
      await this.$axios.delete(`admin/factories/${this.factory_id}`).then(response => {
        this.$router.push("/factory-list");
      });
    },
    async SaveChangesFac() {
      let data = {
        "name": this.FactoryData.name,
        "branch_id": this.FactoryData.branch.id,
      };

      await this.$axios.put(`admin/factories/${this.factory_id}`, data).then(response => {
        this.$router.push("/factory-list");
      });
    },
    async GetCmsList() {
      await this.$axios.get(`admin/factories/${this.factory_id}/agents`).then(response => {
        this.CmsData = response.data.data.agents;
      });
    },
    EditCms(e) {
      this.$router.push(`/cms-editor/${e}`);
    },

  },
};
</script>

<style scoped>
.main-inner-set {
  flex: 1;
}

.list {
  list-style: disc;
}
</style>
